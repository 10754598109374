import * as React from 'react';
import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { Input, Select, Checkbox } from '../Shared/BsInput';
import { Button, Grid, Typography, Box } from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';
import ContainerCard from '../Shared/container-card';
import {
  AddBuildingSchema,
  EditBuildingSchema,
} from '../../validation-schemas';

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  activeDesactive,
}) => {
  let gatewaysOptions = [];
  if (values?.gateways) {
    gatewaysOptions = gatewaysOptions.concat(
      values?.gateways?.map(gateway => {
        return { value: gateway.id, label: gateway.name };
      })
    );
  }
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h5">
            <FormattedMessage id="Basic configuration" />
          </Typography>
          <Input
            type="text"
            value={values.name}
            onChange={handleChange}
            name="name"
            label={<FormattedMessage id="Name" />}
            errors={errors}
          />
          <Input
            type="text"
            value={values.address}
            onChange={handleChange}
            name="address"
            label={<FormattedMessage id="Address" />}
            errors={errors}
          />
          <Select
            value={values.access_types}
            onChange={handleChange}
            name="access_types"
            label={<FormattedMessage id="Allowed access types" />}
            multiple={true}
            options={[
              { value: 'qr', label: 'QR' },
              { value: 'barcode', label: 'BARCODE' },
              { value: 'nfc', label: 'NFC' },
            ]}
            errors={errors}
          />
          <Select
            value={values.access_source}
            onChange={e => {
              setFieldValue('access_gateways', []);

              return handleChange(e);
            }}
            name="access_source"
            label={<FormattedMessage id="Access code origin" />}
            options={[
              {
                value: 'manual',
                label: <FormattedMessage id="Manually loaded" />,
              },
              {
                value: 'online',
                label: <FormattedMessage id="Retrieved online" />,
              },
              {
                value: 'manual-with-online-interaction',
                label: <FormattedMessage id="Manual retrieved online" />,
              },
            ]}
            errors={errors}
          />
          <Select
            value={values.code_algorithm}
            onChange={handleChange}
            name="code_algorithm"
            label={<FormattedMessage id="Employee access code algorithm" />}
            options={[
              {
                value: 'PLAIN',
                label: <FormattedMessage id="Plain access code" />,
              },
              {
                value: 'PREPEND',
                label: (
                  <FormattedMessage id="Prepend facility code to access code" />
                ),
              },
            ]}
            errors={errors}
          />
          <Select
            value={values.code_type}
            onChange={handleChange}
            name="code_type"
            label={<FormattedMessage id="App access code display algorithm" />}
            options={[
              {
                value: 'STATIC',
                label: (
                  <FormattedMessage id="Static. Code is generated with the plain access code" />
                ),
              },
              {
                value: 'DYNAMIC_TOTP_ENCRYPTED',
                label: (
                  <FormattedMessage id="Dynamic. Code is made dynamic using Totp" />
                ),
              },
            ]}
            errors={errors}
          />
          <ChipInput
            value={values.facility_codes}
            onAdd={chip => {
              setFieldValue(
                'facility_codes',
                values.facility_codes.concat(chip)
              );
            }}
            onDelete={(chip, index) => {
              setFieldValue(
                'facility_codes',
                values.facility_codes.filter((e, i) => i !== index)
              );
            }}
            fullWidth
            label={<FormattedMessage id="Facility codes" />}
            helperText={
              <FormattedMessage id="Multiple codes separated by comma" />
            }
            newChipKeyCodes={[9, 13, 188]}
            margin="normal"
            blurBehavior="add"
            style={{ marginBottom: '1.5rem' }}
            error={errors && !!errors.facility_codes}
          />
          <Input
            type="text"
            value={values.code_spec}
            onChange={handleChange}
            name="code_spec"
            errors={errors}
            label={<FormattedMessage id="Access code size" />}
          />
          <br />
          <hr />
          <Checkbox
            name={`config.use_list_keys`}
            value={values?.config?.use_list_keys}
            onChange={handleChange}
          />
          <FormattedMessage id="Use predefined list of keys" />

          <Checkbox
            style={{ marginLeft: '16px' }}
            name={`use_company_keys`}
            value={values?.use_company_keys}
            onChange={handleChange}
          />
          <FormattedMessage id="Use company keys only" />
          <br />
          <Checkbox
            name={`desktop_configuration.use_local_database`}
            value={values?.desktop_configuration?.use_local_database}
            onChange={handleChange}
          />
          <FormattedMessage id="Use local database on desktop" />
          <Box
            display={
              values.code_type === 'DYNAMIC_TOTP_ENCRYPTED' ? 'inherit' : 'none'
            }
            mt={3}
          >
            <Typography variant="h5">
              <FormattedMessage id="Totp and encryption configuration" />
            </Typography>
            <Input
              type="text"
              value={values.totp.encryption_key}
              onChange={handleChange}
              name="totp.encryption_key"
              label={<FormattedMessage id="Encription key (32 chars)" />}
              maxLength={32}
              errors={errors}
            />
            <Input
              type="text"
              value={values.totp.encryption_iv}
              onChange={handleChange}
              name="totp.encryption_iv"
              label={<FormattedMessage id="Encription IV (16 chars)" />}
              maxLength={16}
              errors={errors}
            />
            <Input
              type="text"
              value={values.totp.secret}
              onChange={handleChange}
              name="totp.secret"
              label={<FormattedMessage id="Totp secret (16 chars)" />}
              maxLength={16}
              errors={errors}
            />
            <Input
              type="text"
              value={values.totp.step}
              onChange={handleChange}
              name="totp.step"
              label={<FormattedMessage id="Totp step (seconds)" />}
              errors={errors}
            />
            <Input
              type="text"
              value={values.totp.digits}
              onChange={handleChange}
              name="totp.digits"
              label={<FormattedMessage id="Totp digits" />}
              errors={errors}
            />
            <Select
              value={values.totp.timezone}
              onChange={handleChange}
              name="totp.timezone"
              label={<FormattedMessage id="Totp timezone" />}
              options={[
                { value: 'utc', label: 'UTC' },
                { value: 'America/Sao_Paulo', label: 'Sao Paulo' },
              ]}
              errors={errors}
            />
            <Input
              type="text"
              value={values.totp.clock_encryption}
              onChange={handleChange}
              name="totp.clock_encryption"
              label={<FormattedMessage id="Clock encription (16 chars)" />}
              errors={errors}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <div
            style={{
              display: values.access_source.includes(
                'online',
                'manual-with-online-interaction'
              )
                ? 'inherit'
                : 'none',
            }}
          >
            <Typography variant="h5">
              <FormattedMessage id="Online code configuration (Gateway)" />
            </Typography>
            <Select
              value={values.access_gateways}
              onChange={handleChange}
              name="access_gateways"
              label={<FormattedMessage id="Access Gateways" />}
              options={gatewaysOptions}
              errors={errors}
              multiple
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
            fullWidth
          >
            <FormattedMessage id="Save" />
          </Button>
        </Grid>
        {/* <Grid container spacing={3}>
          <hr />
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
              fullWidth
            >
              <FormattedMessage id="Save" />
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <div style={{ display: values.id ? 'inherit' : 'none'}}>
              <Button
                variant="outlined"
                color={values.active ? "secondary" : "primary"}
                disabled={isSubmitting}
                onClick={() => activeDesactive(values.active)}
                fullWidth
              >
                <FormattedMessage id={values.active ? "Deactivate" : "Activate"} />
              </Button>
            </div>
          </Grid>
        </Grid> */}
      </Grid>
    </form>
  );
};

export default ({ onSubmit, building, isLoading, activeDesactive }) => {
  if (isLoading) {
    return null;
  }

  return (
    <ContainerCard
      xs={12}
      md={12}
      header={
        <Typography variant="h6">
          <i className="fa fa-pencil"></i>&nbsp;
          {building.id ? (
            <FormattedMessage id="Edit building" />
          ) : (
            <FormattedMessage id="Create building" />
          )}
        </Typography>
      }
      content={
        <Formik
          initialValues={building}
          validationSchema={
            building.id ? EditBuildingSchema : AddBuildingSchema
          }
          enableReinitialize={true}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            try {
              await onSubmit(values);
            } catch (err) {
              if (err.response && err.response.data.errors) {
                setErrors(err.response.data.errors);
              }

              setSubmitting(false);
            }
          }}
          render={p => <RenderForm {...p} activeDesactive={activeDesactive} />}
        />
      }
    />
  );
};
