import * as React from 'react';
import * as _ from 'lodash';
import * as moment from 'moment';
import { injectIntl } from 'react-intl';
import { toast } from 'react-toastify';
import KeysForm from '../../components/Keys/form';
import * as BuildingsApi from '../../api/buildings';
import { withRouter } from 'react-router-dom';

class KeysContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      buildingId: this.props.match.params.buildingId,
      companyId: this.props.match.params.companyId,
      record: {
        keys: [],
        valid_until: moment().utc().add(1, 'year').endOf('month').toISOString(),
        // algorithm: 'PLAIN',
        single_use: false,
        access_types: [],
        type: this.props.type,
      },
      isLoading: true,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(body) {
    if (this.props.type === 'guest') {
      await BuildingsApi.createKey(
        this.state.buildingId,
        _.omit(
          {
            ...body,
            company_id: this.state.companyId
              ? Number(this.state.companyId)
              : null,
          },
          ['amount', 'padstart', 'padend', 'step', 'start']
        )
      );
    } else {
      await BuildingsApi.createBuildingKeysList(
        this.state.buildingId,
        _.omit(
          {
            keys: body.keys,
            type: body.type,
            company_id: this.state.companyId
              ? Number(this.state.companyId)
              : null,
          },
          ['amount', 'padstart', 'padend', 'step', 'start']
        )
      );
    }

    toast.success(
      this.props.intl.formatMessage({ id: 'The keys were created' })
    );
    this.props.history.push(
      `/app/buildings/${this.state.buildingId}${
        this.state.companyId ? `/companies/${this.state.companyId}` : ''
      }/keys`
    );
  }

  render() {
    if (this.state.isLoading) {
      return null;
    }

    return (
      <KeysForm
        building={this.state.building}
        company={this.state.company}
        record={this.state.record}
        type={this.props.type}
        onSubmit={this.onSubmit}
      />
    );
  }

  async componentDidMount() {
    const { data } = await BuildingsApi.get(this.state.buildingId);

    let dataCompany = null;

    if (this.state.companyId) {
      const { data: response } = await BuildingsApi.getCompany(
        this.state.buildingId,
        Number(this.state.companyId)
      );
      dataCompany = response;
    }

    this.setState({
      building: data,
      company: dataCompany,
      isLoading: false,
      record: {
        ...this.state.record,
        access_types: data.access_types,
      },
    });
  }
}

export default injectIntl(KeysContainer);
