import * as React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import LoginContainer from '../Login';
import ProfileContainer from '../Profile';
import ChangePwdContainer from '../Profile/change-password';

import BuildingIndexContainer from '../Buildings/index';
import BuildingCreateContainer from '../Buildings/create';
import BuildingEditContainer from '../Buildings/edit';
import BuildingImportContainer from '../Buildings/import-users';
import BuildingUsersContainer from '../Buildings/users';
import BuildingUsersAddContainer from '../Buildings/add-user';

import GatewayIndexContainer from '../Gateways/index';
import GatewayCreateContainer from '../Gateways/create';
import GatewayEditContainer from '../Gateways/edit';
import ScheduledProcesses from '../ScheduledProcesses/index';

import KeysIndexContainer from '../Keys/index';
import KeysCreateContainer from '../Keys/create';

import CompaniesIndexContainer from '../Companies/index';
import CompaniesCreateContainer from '../Companies/create';
import CompaniesEditContainer from '../Companies/edit';

import MainCompaniesIndexContainer from '../MainCompanies/index';
import MainCompaniesCreateContainer from '../MainCompanies/create';
import MainCompaniesEditContainer from '../MainCompanies/edit';

import UsersIndexContainer from '../Users/index';
import UsersEditContainer from '../Users/edit';
import UsersRegistrationStats from '../Users/registration-stats';

import MeetingsIndexContainer from '../Meetings/index';
import MeetingsViewContainer from '../Meetings/view';
import QueueIndexContainer from '../Queues/index';

import AuditViewContainer from '../Audit/index';

import TosIndex from '../Tos';
import TosCreate from '../Tos/create';

import SchedulerEmailsIndex from '../SchedulerEmails';

const PrivateRoute = ({ component: Component, otherProps, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      rest.loggedIn ? <Component {...props} {...otherProps} /> : <Redirect to="/login" />
    }
  />
);

const PublicOnlyRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      rest.loggedIn ? <Redirect to="/app" /> : <Component {...props} />
    }
  />
);

export default ({ loggedIn }) => {
  return (
    <Switch>
      <PrivateRoute
        exact
        path="/app/"
        component={BuildingIndexContainer}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/profile"
        component={ProfileContainer}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/change-password"
        component={ChangePwdContainer}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/buildings"
        component={BuildingIndexContainer}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/buildings/create"
        component={BuildingCreateContainer}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/buildings/import-users"
        component={BuildingImportContainer}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/buildings/:buildingId/users"
        component={BuildingUsersContainer}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/buildings/:buildingId/users/add"
        component={BuildingUsersAddContainer}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/buildings/:buildingId/edit"
        component={BuildingEditContainer}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/buildings/:buildingId/companies"
        component={CompaniesIndexContainer}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/buildings/:buildingId/companies/create"
        component={CompaniesCreateContainer}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/buildings/:buildingId/companies/:companyId/edit"
        component={CompaniesEditContainer}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/buildings/:buildingId/companies/:companyId/keys"
        component={KeysIndexContainer}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/buildings/:buildingId/keys"
        component={KeysIndexContainer}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/buildings/:buildingId/keys/create"
        component={KeysCreateContainer}
        otherProps={{type: "guest"}}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/buildings/:buildingId/companies/:companyId/keys/create"
        component={KeysCreateContainer}
        otherProps={{type: "guest"}}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/buildings/:buildingId/keys/create/permanent"
        component={KeysCreateContainer}
        otherProps={{type: "permanent"}}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/buildings/:buildingId/companies/:companyId/keys/create/permanent"
        component={KeysCreateContainer}
        otherProps={{type: "permanent"}}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/gateways"
        component={GatewayIndexContainer}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/gateways/create"
        component={GatewayCreateContainer}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/gateways/:gatewayId/edit"
        component={GatewayEditContainer}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/Scheduled-processes"
        component={ScheduledProcesses}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/main-companies"
        component={MainCompaniesIndexContainer}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/main-companies/create"
        component={MainCompaniesCreateContainer}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/main-companies/:companyId"
        component={MainCompaniesEditContainer}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/users"
        component={UsersIndexContainer}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/users/registration-stats"
        component={UsersRegistrationStats}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/users/:userId/edit"
        component={UsersEditContainer}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/meetings"
        component={MeetingsIndexContainer}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/queue"
        component={QueueIndexContainer}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/tos"
        component={TosIndex}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/audit"
        component={AuditViewContainer}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/tos/create"
        component={TosCreate}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/meetings/:meetingId"
        component={MeetingsViewContainer}
        loggedIn={loggedIn}
      />
      <PrivateRoute
        exact
        path="/app/scheduler-emails"
        component={SchedulerEmailsIndex}
        loggedIn={loggedIn}
      />
      <PublicOnlyRoute
        exact
        path="/login"
        component={LoginContainer}
        loggedIn={loggedIn}
      />
      <Redirect to={loggedIn ? '/app/' : '/login'} />
    </Switch>
  );
};
