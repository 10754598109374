import * as React from 'react';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';
import {
  TableRow,
  TableCell,
  Button,
  Typography,
  Grid,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ContainerCard from '../Shared/container-card';
import ListTable from '../Shared/list-table';
import Loading from '../Shared/Loading';
import { Select } from '../Shared/BsInput';
import { Workbook } from 'exceljs/dist/exceljs';
import * as FileSaver from 'file-saver';

const RenderTable = ({
  onPageChange,
  pages,
  page,
  registers,
  allRegisters,
}) => {
  return (
    <>
      <ListTable
        onPageChange={onPageChange}
        pages={pages}
        page={page}
        rowsPerPage={20}
        head={
          <TableRow>
            <TableCell>
              <FormattedMessage id="User" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="Company" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="Key" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="Guest" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="Who Allowed" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="Send Date" />
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        }
        body={
          <React.Fragment>
            {registers.map((register, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>{register.nameUser}</TableCell>
                  <TableCell>{register?.object?.guest?.company}</TableCell>
                  <TableCell>{register?.object?.guest?.key}</TableCell>
                  <TableCell>{`${register?.object?.guest?.name} ${register?.object?.guest?.surname}`}</TableCell>
                  <TableCell>{`${
                    register?.object?.who_allowed || ''
                  }`}</TableCell>
                  <TableCell>
                    {moment(register.createdAt).format('DD-MM-YYYY HH:mm')}
                  </TableCell>
                </TableRow>
              );
            })}
          </React.Fragment>
        }
      />
    </>
  );
};

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  users,
  allRegisters,
}) => {
  const intl = useIntl();
  const [loading, setLoading] = React.useState(false);

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const COLUMNS = [
    { header: intl.formatMessage({ id: 'User' }), key: 'user', width: 28 },
    {
      header: intl.formatMessage({ id: 'Company' }),
      key: 'company',
      width: 28,
    },
    { header: intl.formatMessage({ id: 'Key' }), key: 'key', width: 15 },
    { header: intl.formatMessage({ id: 'Guest' }), key: 'guest', width: 28 },
    {
      header: intl.formatMessage({ id: 'Who Allowed' }),
      key: 'who_allowed',
      width: 18,
    },
    {
      header: intl.formatMessage({ id: 'Send Date' }),
      key: 'send_date',
      width: 18,
    },
  ];

  const exportToXlsx = async dataSheet => {
    setLoading(true);

    const newData = allRegisters.map(field => {
      return {
        user: field.nameUser,
        company: field.object?.guest?.company,
        key: field.object?.guest?.key,
        guest: `${field.object?.guest?.name} ${field.object?.guest?.surname}`,
        who_allowed: field.object?.who_allowed || '',
        send_date: moment(field.createdAt).format('DD-MM-YYYY HH:mm'),
      };
    });

    const headerNames = [
      {
        user: `${intl.formatMessage({ id: 'User' })}`,
        company: `${intl.formatMessage({ id: 'Company' })}`,
        key: intl.formatMessage({ id: 'Key' }),
        guest: intl.formatMessage({ id: 'Guest' }),
        who_allowed: intl.formatMessage({ id: 'Who Allowed' }),
        send_date: intl.formatMessage({ id: 'Send Date' }),
      },
    ];

    const wb = new Workbook();
    const ws = wb.addWorksheet(intl.formatMessage({ id: 'Audit' }));
    ws.columns = COLUMNS;

    const title = ws.getRow(1).getCell(1);

    const blackColor = { argb: 'FF000000' };
    const bgHeaderColor = { argb: 'FF' + '73BC73' };
    const defaultBorderStyle = { color: blackColor, style: 'thin' };

    const defaultHeaderFill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: bgHeaderColor,
      bgColor: bgHeaderColor,
    };
    const defaultBorder = {
      top: defaultBorderStyle,
      left: defaultBorderStyle,
      right: defaultBorderStyle,
    };
    const defaultFont = { name: 'Calibri', bold: true, size: 11 };
    const defaultAlignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    title.value = intl.formatMessage({ id: 'Audit' });
    title.alignment = defaultAlignment;
    title.font = { ...defaultFont, size: 14 };
    title.border = defaultBorder;
    title.style.fill = defaultHeaderFill;

    ws.mergeCells('A1', 'F1');
    ws.addRows(headerNames);
    const headerName = ws.getRow(2);
    headerName.height = 40;
    COLUMNS.forEach((item, index) => {
      headerName.getCell(index + 1).style = {
        alignment: defaultAlignment,
        font: defaultFont,
        border: {
          bottom: defaultBorderStyle,
        },
        fill: defaultHeaderFill,
      };
      if (COLUMNS.length === index + 1) {
        headerName.getCell(index + 1).border = {
          right: defaultBorderStyle,
          bottom: defaultBorderStyle,
        };
      }
    });

    ws.addRows(newData);

    const buffer = await wb.xlsx.writeBuffer().finally(() => setLoading(false));
    const file = new Blob([buffer], { type: fileType });
    FileSaver.saveAs(
      file,
      String('Audit_report').toLowerCase().replace(/ /g, '-') + fileExtension
    );
  };

  return (
    <>
      <Loading visible={loading} />
      <form onSubmit={handleSubmit} autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={6} md={4}>
            <Select
              type="text"
              value={values.user}
              onChange={handleChange}
              name="user"
              label={<FormattedMessage id="User" />}
              errors={errors}
              options={[
                ...users.map(u => ({ label: u, value: u })),
                { label: <FormattedMessage id="All" />, value: 'All-Users' },
              ]}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <DateTimePicker
              value={values.init_date}
              onChange={value => {
                if (value) {
                  setFieldValue('init_date', value.format('yyyy-MM-DD'));
                } else {
                  setFieldValue('init_date', null);
                }
              }}
              ampm={false}
              fullWidth
              clearable
              autoOk
              margin="dense"
              format="DD-MM-YYYY"
              views={['year', 'month', 'date']}
              label={<FormattedMessage id="Start date" />}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <DateTimePicker
              value={values.end_date}
              onChange={value => {
                if (value) {
                  setFieldValue('end_date', value.format('yyyy-MM-DD'));
                } else {
                  setFieldValue('end_date', null);
                }
              }}
              minDate={moment(values.init_date)}
              maxDate={moment(values.init_date).add('month', 3)}
              ampm={false}
              fullWidth
              clearable
              autoOk
              margin="dense"
              format="DD-MM-YYYY"
              views={['year', 'month', 'date']}
              label={<FormattedMessage id="End date" />}
            />
          </Grid>
          <Grid item xs={12} md={6} />
          <Grid item xs={12} md={3}>
            <Button
              variant="outlined"
              color="primary"
              onClick={exportToXlsx}
              disabled={isSubmitting || !allRegisters.length}
              fullWidth
            >
              <FormattedMessage id="Export" />
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
              fullWidth
            >
              <FormattedMessage id="Show" />
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default ({
  onPageChange,
  pages,
  page,
  users,
  getRegisters,
  registers,
  allRegisters,
}) => {
  return (
    <ContainerCard
      xs={12}
      md={12}
      header={
        <Typography variant="h6">
          <i className="fa fa-envelope"></i> <FormattedMessage id="Audit" />
        </Typography>
      }
      content={
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div>
            <Formik
              initialValues={{
                end_date: moment().format('yyyy-MM-DD'),
                init_date: moment().subtract(1, 'months').format('yyyy-MM-DD'),
                user: 'All-Users',
              }}
              enableReinitialize={true}
              onSubmit={async (values, { setSubmitting, setErrors }) => {
                try {
                  await getRegisters({
                    user: values.user,
                    init_date: values.init_date,
                    end_date: values.end_date,
                  });
                  setSubmitting(false);
                } catch (err) {
                  if (err.response && err.response.data.errors) {
                    setErrors(err.response.data.errors);
                  }

                  setSubmitting(false);
                }
              }}
              render={p => (
                <RenderForm {...p} {...{ users }} allRegisters={allRegisters} />
              )}
            />
            <hr />
          </div>
          <RenderTable
            {...{ onPageChange, pages, page, registers, allRegisters }}
          />
        </MuiPickersUtilsProvider>
      }
    />
  );
};
