import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  TableRow,
  TableCell,
  Button,
  Typography,
  Grid,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import * as moment from 'moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Input, Select, Checkbox } from '../Shared/BsInput';
import NoRecords from '../Shared/NoRecords';
import ContainerCard from '../Shared/container-card';
import ListTable from '../Shared/list-table';

const isSelected = (selectedIds, id) => {
  return selectedIds.includes(id);
};

const RenderTable = ({
  onPageChange,
  pages,
  page,
  results,
  selectedIds,
  onSelectId,
}) => {
  return (
    <ListTable
      onPageChange={onPageChange}
      pages={pages}
      page={page}
      head={
        <TableRow>
          <TableCell>
            <FormattedMessage id="Code" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Valid until" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Access types" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Single use" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Times used" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Delete" />
          </TableCell>
        </TableRow>
      }
      body={
        <React.Fragment>
          {results.map((c, i) => {
            return (
              <TableRow key={i}>
                <TableCell>{c.key}</TableCell>
                <TableCell>
                  {moment(c.valid_until).utc().format('DD-MM-YYYY HH:mm')}
                </TableCell>
                <TableCell>{c.access_types.join(', ').toUpperCase()}</TableCell>
                <TableCell>{c.single_use ? 'Yes' : 'No'}</TableCell>
                <TableCell>{c.times_used}</TableCell>
                <TableCell>
                  <Checkbox
                    name={`checkbox_${c.id}`}
                    value={isSelected(selectedIds, c.id)}
                    onChange={e => {
                      e.preventDefault();
                      onSelectId(c.id);
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </React.Fragment>
      }
    />
  );
};

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  setFieldValue,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={6} md={3}>
          <Input
            type="text"
            value={values.key}
            onChange={handleChange}
            name="key"
            label={<FormattedMessage id="Code" />}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <DatePicker
            value={values.valid_until}
            onChange={value => {
              if (value) {
                setFieldValue('valid_until', value.format('YYYY-MM-DD'));
              } else {
                setFieldValue('valid_until', null);
              }
            }}
            ampm={false}
            clearable
            fullWidth
            margin="dense"
            format="DD-MM-YYYY"
            views={['year', 'month', 'date']}
            label={<FormattedMessage id="Codes that will be valid until" />}
          />
        </Grid>
        <Grid item xs={6} md={3} />
        <Grid item xs={6} md={3}>
          <Button
            style={{ marginTop: '16px' }}
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
            fullWidth
          >
            <FormattedMessage id="Filter" />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ({
  onPageChange,
  pages,
  page,
  building,
  results,
  onDestroy,
  initialValues,
  company,
}) => {
  const [selectedIds, setSelectedIds] = React.useState([]);

  const onSelectId = id => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter(i => i !== id));
    } else {
      setSelectedIds(selectedIds.concat(id));
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              onSubmit={async (values, { setSubmitting, setErrors }) => {
                try {
                  const val = { ...values };
                  if (val.assigned_to_invitation !== '__none__') {
                    val.type = 'guest';
                  }

                  await onPageChange({ page: 1, ...val });
                  setSubmitting(false);
                } catch (err) {
                  if (err.response && err.response.data.errors) {
                    setErrors(err.response.data.errors);
                  }

                  setSubmitting(false);
                }
              }}
              render={RenderForm}
            />
          </MuiPickersUtilsProvider>
          <hr />
        </Grid>
        <Grid item md={6}>
          {!!selectedIds.length && (
            <Button
              type="button"
              size="small"
              onClick={async () => {
                await onDestroy(selectedIds);

                setSelectedIds([]);
              }}
              color="secondary"
              variant="contained"
            >
              <FormattedMessage
                id="Delete {length} codes"
                values={{ length: selectedIds.length }}
              />
            </Button>
          )}
        </Grid>
        <Grid item md={6}>
          <div className="text-right">
            <Button
              component={Link}
              variant="contained"
              color="primary"
              to={`/app/buildings/${building.id}${
                company ? `/companies/${company.id}` : ''
              }/keys/create`}
            >
              <FormattedMessage id="Add guests' codes" />
            </Button>
            <hr />
          </div>
        </Grid>
      </Grid>
      {!results.length && <NoRecords />}
      {!!results.length && (
        <RenderTable
          {...{
            onPageChange,
            pages,
            page,
            results,
            onSelectId,
            selectedIds,
          }}
        />
      )}
    </React.Fragment>
  );
};
