import * as React from 'react';
import * as _ from 'lodash';
import { toast } from 'react-toastify';
import { injectIntl } from 'react-intl';
import BuildingForm from '../../components/Buildings/form';
import * as BuildingsApi from '../../api/buildings';
import * as GatewaysApi from '../../api/gateways';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmDialog from '../../components/Shared/ConfirmDialog';

const nullify = obj => {
  return Object.keys(obj).reduce((acc, key) => {
    if (_.isString(obj[key]) && obj[key].trim().length === 0) {
      acc[key] = null;
    } else {
      acc[key] = obj[key];
    }

    return acc;
  }, {});
};

class BuildingEditContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      buildingId: this.props.match.params.buildingId,
      building: {},
      isLoading: true,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.activeDesactive = this.activeDesactive.bind(this);
  }

  async onSubmit(body) {
    body.totp = nullify(body.totp);

    await BuildingsApi.update(
      this.state.buildingId,
      _.pick(body, [
        'name',
        'address',
        'access_types',
        'access_source',
        'code_spec',
        'code_type',
        'totp',
        'facility_codes',
        'code_algorithm',
        'access_gateways',
        'config',
        'use_company_keys',
        'desktop_configuration'
      ])
    );

    toast.success(
      this.props.intl.formatMessage({
        id: 'The building information has been updated',
      })
    );
    this.props.history.push('/app/buildings');
  }

  async activeDesactive(active) {
    const changeText = active ? 'deactivate' : 'activate';
    const complementaryText = active ? 'Building will be unacessible' : '';
    const onConfirmClick = async () => {
      if (active) {
        await BuildingsApi.deactivate(this.state.buildingId);
      } else {
        await BuildingsApi.activate(this.state.buildingId);
      }
      toast.success(
        this.props.intl.formatMessage({ id: 'The building has been updated' })
      );
      this.props.history.push('/app/buildings');
    };

    confirmAlert({
      message: this.props.intl.formatMessage({
        id: `Are you sure you want to ${changeText} the building? ${complementaryText}`,
      }),
      customUI: props => <ConfirmDialog {...props} {...{ onConfirmClick }} />,
    });
  }

  render() {
    return (
      <BuildingForm
        onSubmit={this.onSubmit}
        building={this.state.building}
        isLoading={this.state.isLoading}
        activeDesactive={this.activeDesactive}
      />
    );
  }

  async componentDidMount() {
    const { data } = await BuildingsApi.get(this.state.buildingId);
    const { data: gateways } = await GatewaysApi.list({
      attributes: 'id,name',
      where: { disabled: false },
    });

    this.setState({
      building: {
        ...data,
        config: {
          use_list_keys: !!data?.config?.use_list_keys,
        },
        gateways,
      },
      isLoading: false,
    });
  }
}

export default injectIntl(BuildingEditContainer);
