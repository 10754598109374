import * as React from 'react';
import { Formik } from 'formik';
import { Select, Checkbox, Input } from '../Shared/BsInput';
import { Button, Grid, Typography, Box } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import ChipInput from 'material-ui-chip-input';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ContainerCard from '../Shared/container-card';
import { AddKeySchema } from '../../validation-schemas';
import 'react-widgets/dist/css/react-widgets.css';

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  building,
  type,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
            }}
          >
            <div>
              <div className="alert alert-info text-center">
                <i className="fa fa-exclamation-circle"></i>&nbsp;
                <FormattedMessage id="Access codes to be used by guests when the building access type is manual" />
              </div>
              <ChipInput
                value={values.keys}
                onAdd={chip => {
                  setFieldValue('keys', values.keys.concat(chip));
                }}
                onDelete={(chip, index) => {
                  setFieldValue(
                    'keys',
                    values.keys.filter((e, i) => i !== index)
                  );
                }}
                fullWidth
                label={<FormattedMessage id="Access codes" />}
                helperText={
                  <FormattedMessage id="Multiple access codes separated by comma" />
                }
                newChipKeyCodes={[9, 13, 188]}
                margin="normal"
                blurBehavior="add"
                style={{ marginBottom: '1.5rem' }}
                error={errors && !!errors.keys}
              />
            </div>
            {type !== 'permanent' && (
              <>
                <DateTimePicker
                  value={values.valid_until}
                  disablePast
                  onChange={value => {
                    setFieldValue('valid_until', moment(value).utc());
                  }}
                  ampm={false}
                  fullWidth
                  margin="normal"
                  style={{ marginBottom: '1.0rem' }}
                  label={<FormattedMessage id="Codes valid until" />}
                  format="DD-MM-YYYY HH:mm"
                />
                <Select
                  value={values.access_types}
                  onChange={handleChange}
                  name="access_types"
                  label={<FormattedMessage id="Allowed access types" />}
                  multiple={true}
                  options={building.access_types.map(at => ({
                    value: at,
                    label: at.toUpperCase(),
                  }))}
                  errors={errors}
                />
                <div>
                  <Checkbox
                    value={!!values.single_use}
                    onChange={handleChange}
                    name="single_use"
                    errors={errors}
                  />{' '}
                  <FormattedMessage id="Single use (only one guest will be able to use it)" />
                </div>
              </>
            )}
            <hr />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
              fullWidth
            >
              <FormattedMessage id="Save codes" />
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
            }}
          >
            <Typography variant="h6">Generate codes</Typography>
            <Input
              label="Amount of codes"
              name="amount"
              value={values.amount}
              errors={{}}
              type="number"
              onChange={handleChange}
            />
            <Input
              label="Starting value"
              name="start"
              value={values.start}
              errors={{}}
              type="number"
              onChange={handleChange}
            />
            <Input
              label="Prepend value"
              name="padstart"
              value={values.padstart}
              errors={{}}
              onChange={handleChange}
            />
            <Input
              label="Append value"
              name="padend"
              value={values.padend}
              errors={{}}
              onChange={handleChange}
            />
            <Input
              label="Step"
              name="step"
              value={values.step}
              errors={{}}
              onChange={handleChange}
              type="number"
            />
            <Button
              type="button"
              variant="outlined"
              onClick={() => {
                const { amount, start, padstart, padend, step } = values;

                if (!amount || !start || !step) {
                  return;
                }

                const codes = [];

                for (
                  let i = 0;
                  codes.length <= parseInt(amount, 10);
                  i += step
                ) {
                  codes.push(`${padstart || ''}${start + i}${padend || ''}`);
                }

                setFieldValue('keys', codes);
                setFieldValue('amount', '');
                setFieldValue('padstart', '');
                setFieldValue('padend', '');
                setFieldValue('step', '');
                setFieldValue('start', '');
              }}
            >
              Generar
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

export default ({ onSubmit, building, record, type, company }) => {
  return (
    <ContainerCard
      xs={12}
      md={12}
      header={
        <Typography variant="h6">
          <i className="fa fa-pencil"></i> "
          {company ? company.name : building.name}":{' '}
          <FormattedMessage id={`Add ${type}'s access codes`} />
        </Typography>
      }
      content={
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Formik
            initialValues={record}
            validationSchema={AddKeySchema}
            enableReinitialize={true}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
              try {
                await onSubmit(values);
              } catch (err) {
                if (err.response && err.response.data.errors) {
                  setErrors(err.response.data.errors);
                }

                setSubmitting(false);
              }
            }}
            render={p => <RenderForm {...p} {...{ building, type }} />}
          />
        </MuiPickersUtilsProvider>
      }
    />
  );
};
